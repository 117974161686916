<template>
  <div v-loading="loading">
    <!--    <div class="closeBtn" @click="close">-->
    <!--      <i class="el-icon-close"></i>-->
    <!--    </div>-->
    <div class="content_title">
      <div class="one_title">品牌名称</div>
      <div class="tow_title">{{ previewData.brand }}</div>
    </div>
    <div class="content_box">
      <div class="content_left">
        <div class="content_imgs">
          <div class="icon">
            <el-image :src="cover_info"></el-image>
            <span>品牌LOGO</span>
          </div>
          <el-image class="imgs" style=""
                    :src="previewData?.brand_logo">
            <div slot='error' class='image-slot'>
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </div>
      <div class="content_right">
        <div class="row">
          <div class="icon">
            <img :src="sp"/>
          </div>
          <div class="row_item">
            <div class="label">品牌背书</div>
            <div class="value">{{ previewData.brand_intro }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="pt"/>
          </div>
          <div class="row_item">
            <div class="label">所属集团</div>
            <div class="value">{{ previewData.group?.display_name }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="jl"/>
          </div>
          <div class="row_item">
            <div class="label">激励政策</div>
            <div class="value">{{ previewData.policy }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_bottom">
      <h1>产品列表</h1>
      <div class="productBox">
        <div @click="routerInfo(item.id)" class="info" :key="item.id" v-for="item in previewData.product_list">
          <span>
          {{ item.product }}
          </span>
        </div>
      </div>
    </div>
    <el-dialog
        append-to-body
        :visible.sync="dialogVisible"
        fullscreen
        v-if="dialogVisible"
        :destroy-on-close="true"
    >
      <product-details :previewData="infoData"
                       :action="false" :loading="loading" @close="dialogVisible=false"></product-details>
    </el-dialog>
  </div>
</template>
<script>
import cover_info from './assets/cover_info.png'
import intro from './assets/intro.png'
import sp from './assets/sp.png'
import pt from './assets/pt.png'
import bl from './assets/bl.png'
import fb from './assets/fb.png'
import yj from './assets/yj.png'
import jl from './assets/jl.png'
import qw from './assets/qw.png'
import zb from './assets/zb.png'
import ty from './assets/ty.png'
import dj from './assets/dj.png'
import bj from './assets/bj.png'
import ProductDetails from '@/pages/inquiries/productDetails.vue'

export default {
  name: 'brandDetails',
  components: { ProductDetails },
  props: {
    previewData: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    previewData: {
      deep: true,
      handler() {
        this.$forceUpdate()
      }
    }
  },
  data() {
    return {
      cover_info: cover_info,
      intro: intro,
      showIndex: 0,
      sp: sp,
      pt: pt,
      bl: bl,
      fb: fb,
      yj: yj,
      jl: jl,
      qw: qw,
      zb: zb,
      ty: ty,
      dj: dj,
      bj: bj,
      dialogVisible: false,
      infoData: {}
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async routerInfo(id) {
      let { info } = await this.$api.InquiryProjectItemInfo({ id: id })
      this.infoData = info
      console.log(info)
      this.dialogVisible = true
    }
  }
}

</script>
<style lang="scss" scoped>

.closeBtn {
  position: fixed; /* 绝对定位，不管滚动条上下滚动都在相应的位置*/
  z-index: 999;
  font-size: 30px;
  top: 10px;
  right: 30px;
  cursor: pointer;
}


.content_title {
  width: 80%;
  height: 40px;

  background-image: linear-gradient(to right, #e04140, black 90%);


  //background: #e04140;
  border-radius: 10px;

  .one_title, .tow_title {
    font-size: 20px;
    line-height: 40px;
    float: left;
  }

  .one_title {
    width: 200px;
    background-color: #ebebeb;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: relative;
  }

  .one_title::before {
    content: '';
    right: 0;
    position: absolute;
    left: 100%;
    top: 0;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 40px solid #b93535;
  }

  .tow_title {
    z-index: 2;
    margin-left: 40px;
    color: white;
    letter-spacing: 4px;
  }
}

.content_box {
  margin: 20px 0;
  height: 320px;

  .content_left {
    //width: 300px;
    //background-color: #f0eded;
    //border-radius: 10px;
    //padding: 20px 0;
    float: left;
    border: 20px solid #f0eded;
    //border-radius: 50%;

    .content_imgs {
      display: block;
      width: 260px;
      height: 260px;
      background-color: white;
      margin: 0 auto;
      position: relative;
      z-index: 2;

      .icon {
        position: absolute;
        width: 140px;
        left: 50%;
        transform: translate(-50%);
        z-index: 0;

        > span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 14px;
          font-weight: bold;

        }
      }

      .imgs {
        width: 70%;
        max-height: 70%;
        margin: 0 auto;
        padding-top: 50px;
        display: block;
      }
    }
  }

  .content_right {
    float: left;
    width: calc(80% - 340px);
    padding: 10px;
    margin: 0 20px;

    .row {
      display: flex;
      align-items: center; /* 垂直居中 */

      .icon {
        width: 24px;
        height: 24px;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .row_item {
        vertical-align: middle;
        width: calc(100% - 40px);
        border-bottom: 1px solid #cccccc;
        padding-bottom: 10px;
        color: black;
        font-size: 18px;
        margin-top: 13px;
        margin-left: 10px;
        display: flex;

        align-items: center; /* 垂直居中 */
        .label, .value {
          display: inline-block;
        }

        .label {
          width: 200px;
          font-weight: bold;
        }

        .value {
          width: calc(100% - 200px);

        }
      }
    }

  }
}

.content_bottom {
  clear: both;
  width: 100%;
  //min-height: 280px;
  margin: 0 auto;
  background-color: #f7f6f6;
  overflow: hidden;
  border-radius: 10px;
  min-height: 490px;

  > h1 {
    padding: 10px 0;
    font-size: 26px;
    text-align: center;
  }

  .productBox {
    display: grid;
    width: 86%;
    margin: 0 auto;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;

    .info {
      cursor: pointer;
      height: 80px;
      background-color: #e1e1e1;
      border-radius: 10px;
      text-align: center;
      color: white;
      font-size: 20px;

      > span {
        display: inline-block;
        //line-height: 80px;
        margin: 10px 0;

      }
    }

    .info:hover {
      background-image: linear-gradient(to right, #e43332, #f85959 90%);
    }
  }

}

.opt-box {
  width: 96%;
  //line-height: 40px;
  position: absolute;
  bottom: 30px;
  opacity: 0.3;
}

.opt-box:hover {
  opacity: 1;
  transition-duration: 0.3s;

}

::v-deep .el-divider__text {
  background-color: #ebebeb;
  font-size: 18px;
  color: #666666;
}

::v-deep .image-slot {
  text-align: center;
  margin: 26% auto;
  width: 50%;
  height: 50%;
  font-size: 100px;
}
::v-deep .el-dialog__body {
  padding: 2px 20px;
}
</style>