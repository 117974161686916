import { render, staticRenderFns } from "./ApeUploader.vue?vue&type=template&id=1752b1d6&scoped=true"
import script from "./ApeUploader.vue?vue&type=script&lang=js"
export * from "./ApeUploader.vue?vue&type=script&lang=js"
import style0 from "./ApeUploader.vue?vue&type=style&index=0&id=1752b1d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1752b1d6",
  null
  
)

export default component.exports